import {
  concatTransformationMatrix,
  pushGraphicsState,
  popGraphicsState,
  PDFDocument,
  PDFImage,
  PDFTextField,
  StandardFonts,
} from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

function degreeToRadian(degree: number) {
  return degree * (Math.PI / 180);
}

async function pdfImageFromFile(pdf: PDFDocument, file: File) {
  // Create PDF from image file
  let imagePdf: PDFImage;
  const arrayBuffer = await file.arrayBuffer();

  if (file.type === 'image/jpeg') {
    imagePdf = await pdf.embedJpg(arrayBuffer);
  } else if (file.type === 'image/png') {
    imagePdf = await pdf.embedPng(arrayBuffer);
  } else {
    throw new Error(`Image type ${file.type} NOT supported`);
  }

  return imagePdf;
}

async function addImageFileToPdf(pdf: PDFDocument, file: File) {
  const imagePdf = await pdfImageFromFile(pdf, file);
  const pdfPage = pdf.addPage();
  const { width: pageWidth, height: pageHeight } = pdfPage.getSize();
  const centerX = pageWidth / 2;
  const centerY = pageHeight / 2;

  // Adjust dimensions
  // Make sure the image is not larger than the page, and scale down to fit if it is
  let imageDims = imagePdf.size();
  if (imageDims.width > pageWidth || imageDims.height > pageHeight) {
    imageDims = imagePdf.scaleToFit(pageWidth, pageHeight);
  }

  if (imageDims.width > imageDims.height) {
    const degrees = -90;

    pdfPage.pushOperators(
      pushGraphicsState(),
      concatTransformationMatrix(1, 0, 0, 1, centerX, centerY),
      concatTransformationMatrix(
        Math.cos(degreeToRadian(degrees)),
        Math.sin(degreeToRadian(degrees)),
        -Math.sin(degreeToRadian(degrees)),
        Math.cos(degreeToRadian(degrees)),
        0,
        0,
      ),
      concatTransformationMatrix(1, 0, 0, 1, -1 * centerX, -1 * centerY),
    );

    pdfPage.drawImage(imagePdf, {
      x: centerX - imageDims.width / 2,
      y: centerY - imageDims.height / 2,
      width: imageDims.width,
      height: imageDims.height,
    });

    pdfPage.pushOperators(popGraphicsState());
  } else {
    pdfPage.drawImage(imagePdf, {
      x: centerX - imageDims.width / 2,
      y: centerY - imageDims.height / 2,
      width: imageDims.width,
      height: imageDims.height,
    });
  }
}

async function mergeFileToPdf(pdf: PDFDocument, file: File) {
  const buffer = await file.arrayBuffer();
  const filePdf = await PDFDocument.load(buffer);
  const pages = await pdf.copyPages(filePdf, filePdf.getPageIndices());

  pages.forEach((page) => pdf.addPage(page));
}

export async function mergeFilesToPdf(files: File[]) {
  const mergedPdf = await PDFDocument.create();

  for (const file of files) {
    if (file.type === 'application/pdf') {
      await mergeFileToPdf(mergedPdf, file);
    } else if (file.type.startsWith('image/')) {
      await addImageFileToPdf(mergedPdf, file);
    }
  }

  return mergedPdf.save();
}

export async function fillPdfFileFromBuffer(
  buffer: ArrayBuffer,
  data: Record<string, string>,
) {
  const pdf = await PDFDocument.load(buffer);
  pdf.registerFontkit(fontkit);

  const form = pdf.getForm();
  const fields = form.getFields();

  const calligraphyFontBytes = await fetch('Breathney.ttf').then((res) =>
    res.arrayBuffer(),
  );
  const calligraphyFont = await pdf.embedFont(calligraphyFontBytes);
  const timesFont = await pdf.embedFont(StandardFonts.TimesRoman);

  const standardFields = ['nomcomplet', 'datesignature', 'email'];
  const calligraphyFields = ['paraphe', 'signature'];

  for (const field of fields) {
    const name = field.getName();

    if (field instanceof PDFTextField) {
      field.setText(data[name] ?? '');

      if (standardFields.includes(name)) {
        field.defaultUpdateAppearances(timesFont);
      } else if (calligraphyFields.includes(name)) {
        field.defaultUpdateAppearances(calligraphyFont);
      }
    }
  }

  form.flatten();
  return pdf.save();
}
